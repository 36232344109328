import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from 'react';

interface SearchInputProps {
  value?: string;
  placeholder: string;
  variant?: TextFieldVariants;
  size?: 'medium' | 'small';
  searchIcon?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value = '',
  placeholder,
  variant = 'outlined',
  size = 'medium',
  searchIcon = true,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleInput = React.useCallback(
    (e: any) => {
      setInputValue(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const InputSearchIcon = searchIcon
    ? {
        endAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }
    : {};

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <TextField
        id="input-with-icon-textfield"
        label={placeholder}
        InputProps={InputSearchIcon}
        value={inputValue}
        onChange={handleInput}
        variant={variant}
        size={size}
      />
    </Box>
  );
};

export default SearchInput;
