import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

export interface ModalProps {
  title: string;
  open: boolean;
  children: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  confirmButtonLoading?: boolean;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
}

const Modal: React.FC<ModalProps> = ({
  title,
  open,
  children,
  maxWidth = 'sm',
  confirmButtonLoading = false,
  onConfirm,
  onClose,
  onSubmit,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(open);

  const handleClose = (event: any) => {
    onClose(event);
    setModalOpen(false);
  };
  useEffect(() => setModalOpen(open), [open]); //NOTE: è giusto?

  const handleConfirm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onConfirm) onConfirm(event);
  };

  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        open={modalOpen}
        onClose={handleClose}
        PaperProps={{ component: 'form', onSubmit: onSubmit }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <LoadingButton
            onClick={handleConfirm}
            loading={confirmButtonLoading}
            autoFocus
            type="submit"
          >
            Conferma
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
