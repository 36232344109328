import * as React from 'react';
import { Snackbar } from '@mui/material';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

interface CopyToClipboardButtonProps {
  elementToCopy: string;
  copiedMesssage?: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  elementToCopy,
  copiedMesssage = 'Elemento copiato!',
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(elementToCopy);
  };

  return (
    <>
      <IconButton aria-label="copy" size="small" onClick={handleClick}>
        <ContentCopyIcon />
      </IconButton>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={copiedMesssage}
      />
    </>
  );
};

export default CopyToClipboardButton;
