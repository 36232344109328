import React, { useEffect } from 'react';
import { Alert, AlertTitle, CircularProgress, Grow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Navbar from './Navbar';

interface BaseLayoutProps {
  children: React.ReactNode | any;
  isLoading?: boolean;
  errorMessage: any | null;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  isLoading = false,
  errorMessage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') as string);
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const pageContent = () => {
    if (errorMessage && [401, 403].includes(errorMessage?.code)) {
      return (
        <div className="relative flex w-full h-[70vh] justify-center items-center">
          <Alert severity="info">
            <AlertTitle>info</AlertTitle>
            Autenticazione in corso!
          </Alert>
        </div>
      );
    }
    // TODO: Creare componente error message
    if (errorMessage) {
      return (
        <div className="relative flex w-full h-[70vh] justify-center items-center">
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Ops...qualcosa è andato storto, riprova!
          </Alert>
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className="relative flex w-full h-[70vh] justify-center items-center">
          <CircularProgress />
        </div>
      );
    }
    return (
      <Grow in={!isLoading} timeout={700}>
        {children}
      </Grow>
    );
  };

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <div className="max-w-[100rem] mx-auto overflow-x-hidden">
        {pageContent()}
      </div>
    </div>
  );
};
export default BaseLayout;
