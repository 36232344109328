import { ClientsSummaryProps, CLientSummaryProps } from '../schema/clients';
import { useFetchData } from './ApiManager';
import { ENDPOINTS } from './EndPoints';

export const useGetClient = (id: string) => {
  const fetch = id !== '';
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_CLIENT(id),
    fetch
  );
  const clientId = apiData?.client_id;
  const clientName = apiData?.client_name;
  return { clientId, clientName, isPending, errorMessage };
};

export const useGetClients = () => {
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_CLIENTS
  );
  const clientsList = apiData?.clients_list.map((client: any) => ({
    label: client.client_name,
    value: client.client_id,
  }));
  return { clientsList, isPending, errorMessage };
};

export const useGetClientsSummary: () => ClientsSummaryProps = () => {
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_CLIENTS_SUMMARY
  );
  const clientsSummary: CLientSummaryProps[] =
    apiData?.clients_summary || ([] as CLientSummaryProps[]);

  return { clientsSummary, isPending, errorMessage };
};
