import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import PostAddIcon from '@mui/icons-material/PostAdd';
import BaseTable, { BaseTableColumnProps } from './Table/BaseTable';
import Modal from './Modal';
import { useState } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { useApiCall } from '../api/ApiManager';
import { ENDPOINTS } from '../api/EndPoints';
import Snackbar from '@mui/material/Snackbar';
import { useGetReportDetail } from '../api/ReportsAPI';

interface ReportDetailProps {
  reportId: string;
  clientId: string;
}

const ReportDetail: React.FC<ReportDetailProps> = ({ reportId, clientId }) => {
  const [whiteListModalOpen, setWhiteListModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [whiteListAssetId, setWhiteListAssetId] = useState<number>(0);
  const { makeCall } = useApiCall();

  const {
    reportName,
    reportDescription,
    reportDate,
    reportDetailColumns,
    reportDetail,
    reportIsWhiteListable,
    isPending,
  } = useGetReportDetail(reportId);

  if (!reportId) {
    return (
      <div className="relative flex justify-center items-center w-full h-full">
        <p className="text-3xl font-bold text-gray-500">
          Seleziona un controllo
        </p>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="relative flex w-full h-full justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const csvData = [
    reportDetailColumns.map((column: BaseTableColumnProps) => column.name),
    ...reportDetail.map((row: any) => Object.values(row).flat(1)),
  ];

  const csvFileName = `${reportName.replace(/\s/g, '_')}_${
    reportDate.split(',')[0]
  }.csv`;

  const handleWhiteListOnClick = (assetId: number) => {
    setWhiteListAssetId(assetId);
    setWhiteListModalOpen(true);
  };

  const handleSnakbarMessageOnClose = () => {
    setSnackbarMessage('');
  };

  const handleWhiteListModalClose = () => {
    setWhiteListModalOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setModalConfirmLoading(true);
    makeCall(ENDPOINTS.GET_ANA_REPORTS_BY_REPORT(reportId), 'get')
      .then((response) => {
        const postData = {
          id_client: parseInt(clientId),
          id_ana_report: response.data.report_id,
          id_asset: whiteListAssetId,
        };
        makeCall(ENDPOINTS.POST_WHITELIST_REPORT, 'post', postData)
          .then(() => {
            setSnackbarMessage("L'elemento è stato aggiunto alla whitelist");
            handleWhiteListModalClose();
            setModalConfirmLoading(false);
          })
          .catch((error: any) => {
            setSnackbarMessage('Ops...qualcosa è andato storto, riprova!');
            setModalConfirmLoading(false);
          });
      })
      .catch((error: any) => {
        setSnackbarMessage('Ops...qualcosa è andato storto, riprova!');
        setModalConfirmLoading(false);
      });
  };

  const tableActions = reportIsWhiteListable
    ? [
        {
          label: 'Aggiungi alla whitelist',
          onClick: handleWhiteListOnClick,
          icon: <PostAddIcon />,
        },
      ]
    : undefined;

  return (
    <div className="flex flex-col w-full h-full space-y-3">
      <div className="flex flex-col space-y-1">
        <div className="flex w-full justify-between">
          <h2 className="text-xl font-semibold">Descrizione</h2>
          <p className="mr-2">{reportDate}</p>
        </div>
        <p>{reportDescription}</p>
      </div>
      <div className="flex flex-col space-y-2 h-full">
        <div className="flex w-full justify-between">
          <h2 className="text-xl font-semibold">Dettagli</h2>
          <Button
            className="mr-2"
            variant="contained"
            startIcon={<DownloadIcon />}
          >
            <CSVLink
              className="capitalize"
              filename={csvFileName}
              data={csvData}
            >
              Export to CSV
            </CSVLink>
          </Button>
        </div>
        <BaseTable
          classNames="relative h-fit max-h-[60vh]"
          columns={reportDetailColumns.map((column) => ({
            ...column,
            enableCopy: true,
          }))}
          rows={reportDetail}
          rowsCount={true}
          actions={tableActions}
        />
      </div>
      <Modal
        title={'Sei sicuro di voler aggiungere alla WhiteList?'}
        open={whiteListModalOpen}
        onClose={handleWhiteListModalClose}
        confirmButtonLoading={modalConfirmLoading}
        onSubmit={handleSubmit}
      >
        <DialogContentText>
          Verifica che il seguente id asset è quello che vuoi aggiungere alla
          whitelist: {whiteListAssetId}
        </DialogContentText>
      </Modal>
      <Snackbar
        open={snackbarMessage !== undefined && snackbarMessage !== ''}
        onClose={handleSnakbarMessageOnClose}
        autoHideDuration={2000}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ReportDetail;
