import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';

export interface MenuItemProps {
  value: string;
  label: string;
  group?: string;
  disabled?: boolean;
}

interface BasicSelectProps {
  placeholder?: string;
  defaultSelected?: string;
  menuItems: MenuItemProps[];
  variant?: 'standard' | 'outlined' | 'filled';
  toggleSelectState: (value: string) => void;
  isRequired?: boolean;
  classNames?: string;
}
//TODO: Modificare la logica per la visualizzazione in errore della lista
const BasicSelect: React.FC<BasicSelectProps> = ({
  placeholder,
  defaultSelected = '',
  menuItems,
  variant,
  toggleSelectState,
  isRequired = false,
  classNames = '',
}) => {
  const [selectValue, setSelectValue] = useState<string>(defaultSelected);

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectValue(value);
    toggleSelectState(value);
  };

  const defaultValueInItems =
    defaultSelected === '' ||
    menuItems
      .map((item) => item.value.toString())
      .includes(selectValue.toString());
  const isInError = (isRequired && selectValue === '') || !defaultValueInItems;
  return (
    <Box sx={{ width: '100%' }} className={classNames}>
      <FormControl fullWidth variant={variant} error={isInError}>
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select value={selectValue} label={placeholder} onChange={handleChange}>
          {!isRequired && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {!defaultValueInItems && (
            <MenuItem disabled value={defaultSelected}>
              {defaultSelected}
            </MenuItem>
          )}
          {menuItems?.map((item, index) => (
            <MenuItem key={`${item.value}_${item.label}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
