import {
  RESET_TABLE_ROWS,
  SET_TABLE_HEADERS,
  UPDATE_TABLE_ROWS,
} from '../actions/anagraphicTableActions';

const initialState = {
  headers: [],
  rows: [] as any,
};

const headerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TABLE_HEADERS:
      return { ...state, headers: action.payload };
    case UPDATE_TABLE_ROWS:
      const { id, ...rest } = action.payload;
      const existingId = state.rows.findIndex((el: any) => el.id === id);
      if (existingId !== -1) {
        // Se l'oggetto esiste già, lo sostituisco con il nuovo oggetto
        const newRows = [...state.rows];
        newRows[existingId] = { ...newRows[existingId], ...rest };
        return { ...state, rows: newRows };
      }

      return { ...state, rows: [...state.rows, { id, ...rest }] };
    case RESET_TABLE_ROWS:
      return initialState;

    default:
      return state;
  }
};

export default headerReducer;
