/* eslint-disable no-nested-ternary */
import { CircularProgress, Typography } from '@mui/material';
import './Home.module.css';
import { useEffect, useState } from 'react';
import BaseLayout from '../components/Layout/BaseLayout';
import CircularProgressWithLabel from '../components/CircularStatus';
import SelectedList from '../components/Select/SelectedList';
import ReportDetail from '../components/ReportDetail';
import {
  useGetReportPage,
  useUpdateReportsList,
} from '../handler/ReportPageHandler';
import BasicSelect from '../components/Select/BasicSelect';
import AutoCompleteSelect from '../components/AutoCompleteSearch';
import { useGetAnaReports } from '../api/ReportsAPI';

const reportsOrderList = [
  { label: 'Criticità: crescente', value: 'severity.asc' },
  { label: 'Criticità: decrescente', value: 'severity.desc' },
];

const Reports = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const preSelectedClientId = queryParameters.get('client_cod');
  const preSelectedOrder = queryParameters.get('order') || undefined;

  const [report, setReport] = useState<string>('');
  const [clientId, setClientId] = useState<string>(preSelectedClientId ?? '');

  const {
    percentPassedReports,
    clientsList,
    isPending,
    errorMessage: reportPageErrorMessage,
  } = useGetReportPage();

  const {
    reportsTypeList,
    isPending: reportsTypeListPending,
    errorMessage: reportsTypeListErrorMessage,
  } = useGetAnaReports(clientId, 'Functional');

  const [reportType, setReportType] = useState(
    reportsTypeList ? reportsTypeList[0].value : ''
  );
  const [reportsListOrder, setReportsListOrder] = useState<string | undefined>(
    preSelectedOrder
  );

  const {
    reportsList,
    isPending: reportsListPending,
    errorMessage: reportsListErrorMessage,
  } = useUpdateReportsList(
    clientId,
    reportType,
    reportsListOrder,
    'Functional'
  );

  const toggleListState = (index: string) => {
    setReport(index);
  };
  const toggleClientSelectState = (value: string) => {
    setClientId(value);
    if (report) setReport('');
  };
  const toggleReportSelectState = (value: string) => {
    setReportType(value);
    if (report) setReport('');
  };
  const toggleReportsOrderSelectState = (value: string) => {
    setReportsListOrder(value);
  };

  useEffect(() => {
    if (reportsList?.length === 0 && !reportsListPending) {
      setReport('');
    }
  }, [reportsList, reportsListPending, reportType, clientId]);

  const passedReportsMessage =
    percentPassedReports === 100
      ? 'Tutti i test sono passati con successo'
      : 'Alcuni test non sono passati';

  const errorMessage =
    reportsListErrorMessage ??
    reportPageErrorMessage ??
    reportsTypeListErrorMessage;

  return (
    <BaseLayout isLoading={isPending} errorMessage={errorMessage}>
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-row justify-between items-center m-10">
          <Typography
            variant="h1"
            component="div"
            color="gray"
            fontSize="4rem"
            fontWeight="700"
          >
            Benvenuto/a
          </Typography>
          <div className="flex justify-between items-center space-x-2 bg-gray-100 rounded-lg p-2">
            <Typography
              variant="caption"
              component="div"
              color="black"
              fontSize="1rem"
            >
              {passedReportsMessage}
            </Typography>
            <CircularProgressWithLabel value={percentPassedReports} />
          </div>
        </div>
        <div
          className="flex relative flex-col w-[97%] h-full min-h-[70vh] mx-auto
                    rounded bg-sky-100 p-4 my-10"
        >
          <div className="flex flex-row w-1/2 justify-center space-x-3 mx-auto my-7">
            <BasicSelect
              toggleSelectState={(value) => toggleClientSelectState(value)}
              defaultSelected={clientId}
              placeholder="Seleziona cliente"
              menuItems={clientsList}
              isRequired={true}
              classNames="min-w-80"
            />
            <AutoCompleteSelect
              toggleSelectState={(value) => toggleReportSelectState(value)}
              placeholder="Seleziona segnalazione"
              menuItems={reportsTypeList}
              isLoading={reportsTypeListPending}
            />
          </div>
          <div className="flex flex-row w-full h-full">
            <div className="basis-1/4">
              <div className="w-full h-full">
                {reportsList?.length > 0 && (
                  // TODO: Aggiungere l'elemento di default se preselected
                  <BasicSelect
                    toggleSelectState={(value) =>
                      toggleReportsOrderSelectState(value)
                    }
                    placeholder="Ordina per"
                    menuItems={reportsOrderList}
                    classNames="min-w-80"
                  />
                )}
                {!clientId ? (
                  <p>Seleziona una segnalazione</p>
                ) : reportsListPending ? (
                  <div className="flex h-full justify-center items-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <SelectedList
                    items={reportsList}
                    toggleListState={(index) => toggleListState(index)}
                  />
                )}
              </div>
            </div>

            <div
              className="relative inline-block my-5 mx-3 w-px self-stretch min-h-[70vh]
             bg-gray-700"
            />
            <div className="w-full overflow-auto basis-auto">
              <ReportDetail reportId={report} clientId={clientId} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Reports;
