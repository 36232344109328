import { useGetClients, useGetClient } from '../api/ClientsApi';
import {
  useGetPercentPassedReports,
  useGetReportsWithFilters,
} from '../api/ReportsAPI';

export const useGetReportPage = () => {
  const {
    percentPassedReports,
    isPending: percentIsPending,
    errorMessage: percentErrorMessage,
  } = useGetPercentPassedReports();
  const {
    clientsList,
    isPending: clientsIsPending,
    errorMessage: clientsErrorMssage,
  } = useGetClients();

  const isPending = clientsIsPending || percentIsPending;
  const errorMessage = percentErrorMessage ?? clientsErrorMssage;
  return { percentPassedReports, clientsList, isPending, errorMessage };
};

export const useUpdateReportsList = (
  clientId: string,
  reportTypeId: string,
  ordering?: string,
  metagroup?: string
) => {
  const splittedOrdering = ordering?.split('.');
  const order =
    splittedOrdering && splittedOrdering?.length > 1
      ? { field: splittedOrdering[0], order: splittedOrdering[1] }
      : undefined;
  const {
    reportsList: reports,
    isPending: reportsPending,
    errorMessage: reportsWFiltersErrorMessage,
  } = useGetReportsWithFilters(clientId, reportTypeId, order, metagroup);
  const {
    clientName,
    isPending: clientPending,
    errorMessage: clientErrorMessage,
  } = useGetClient(clientId);
  const reportsList = reports?.map((report: any) => ({
    ...report,
    clientName,
  }));
  const isPending = clientPending || reportsPending;
  const errorMessage = reportsWFiltersErrorMessage ?? clientErrorMessage;

  return { reportsList, isPending, errorMessage };
};
