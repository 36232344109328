import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useState } from 'react';

interface SelectedListItemProps {
  reportName: string;
  reportId: string;
  reportClientId: string;
  clientName: string;
  isCritical?: boolean;
}

interface SelectedListProps {
  items: SelectedListItemProps[];
  toggleListState: (index: string) => void;
}
const SelectedList: React.FC<SelectedListProps> = ({
  items,
  toggleListState,
}) => {
  const [selectedIndex, setSelectedIndex] = useState('');

  const handleListItemClick = (value: string, index: string) => {
    setSelectedIndex(index);
    toggleListState(value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 360,
        maxHeight: 900,
        overflow: 'auto',
      }}
    >
      <List component="nav" className="py-0">
        {items?.length > 0 ? (
          items.map((item, index) => (
            <ListItemButton
              key={`${item.reportClientId}_${index * 2}`}
              selected={selectedIndex === index.toString()}
              onClick={() =>
                handleListItemClick(item.reportId, index.toString())
              }
              className="!p-0"
            >
              <div
                className={`p-2 ${item.isCritical ? 'border-l-2 border-red-500' : ''} w-full my-1`}
              >
                <div className="flex flex-row justify-between items-center w-full">
                  <h3 className="text-lg">{item.reportName}</h3>
                  <p className="text-sm text-gray-500">{item.clientName}</p>
                </div>
              </div>
            </ListItemButton>
          ))
        ) : (
          <p>Non sono stat trovati segnalazioni</p>
        )}
      </List>
    </Box>
  );
};

export default SelectedList;
