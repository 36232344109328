import { Typography } from '@mui/material';
import './Home.module.css';
import BaseLayout from '../components/Layout/BaseLayout';
import CircularProgressWithLabel from '../components/CircularStatus';
import SortedTable, {
  SortedTableColumnProps,
} from '../components/Table/SortedTable';
import { useGetSummary } from '../handler/HomepageHandler';
import { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

const Home = () => {
  const { percentPassedReports, clientsSummary, isPending, errorMessage } =
    useGetSummary();
  const [isExploding, setIsExploding] = useState(false);
  const handleExplosion = () => {
    if (percentPassedReports === 100) setIsExploding(!isExploding);
  };
  const columns: SortedTableColumnProps[] = [
    { label: 'Cliente', id: 'client_name' },
    { label: 'Segnalazioni critiche', id: 'critical_reports_count' },
    { label: 'Segnalazioni funzionali', id: 'functional_reports_count' },
    { label: 'Segnalazioni anagrafiche', id: 'is_anagraphic_reports' },
    { label: 'Percentuale test superati', id: 'total_reports_percent' },
    { label: 'Ultimo agg. cliente', id: 'client_upadate_date' },
  ];

  const passedReportsMessage =
    percentPassedReports === 100
      ? 'Tutti i test sono passati con successo'
      : 'Alcuni test non sono passati';

  return (
    <BaseLayout isLoading={isPending} errorMessage={errorMessage}>
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-row justify-between items-center m-10">
          <Typography
            variant="h1"
            component="div"
            color="gray"
            fontSize="4rem"
            fontWeight="700"
          >
            Benvenuto/a
          </Typography>
          <div className="flex justify-between items-center space-x-2 bg-gray-100 rounded-lg p-2">
            <Typography
              variant="caption"
              component="div"
              color="black"
              fontSize="1rem"
            >
              {passedReportsMessage}
            </Typography>

            <div onClick={handleExplosion} className="cursor-pointer">
              <CircularProgressWithLabel value={percentPassedReports} />
              {isExploding && (
                <ConfettiExplosion
                  duration={3000}
                  particleCount={500}
                  width={2000}
                />
              )}
            </div>
          </div>
        </div>
        <div className="p-2 w-full">
          <SortedTable columns={columns} rows={clientsSummary} />
        </div>
      </div>
    </BaseLayout>
  );
};

export default Home;
