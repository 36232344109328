import Typography from '@mui/material/Typography';
import BaseLayout from '../components/Layout/BaseLayout';
import EditableAnagraphicTable from '../components/Table/EditableAnagraphicTable';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetClient } from '../api/ClientsApi';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable, { BaseTableColumnProps } from '../components/Table/BaseTable';
import { useNavigate } from 'react-router-dom';
import { usePutData } from '../api/ApiManager';
import LoadingButton from '@mui/lab/LoadingButton';
import { ENDPOINTS } from '../api/EndPoints';
import { Dict } from '../schema/general';
import { resetTableRows } from '../actions/anagraphicTableActions';

const EditClientAnagraphy = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const preSelectedClientId = queryParameters.get('client_cod');
  const [clientId, setClientId] = useState<string>(preSelectedClientId ?? '');
  const { clientName, isPending, errorMessage } = useGetClient(clientId);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const rowsSelector: Dict[] = useSelector((state: any) => state.rows);

  const modalColumns: BaseTableColumnProps[] = Array.from(
    new Set(rowsSelector.flatMap((obj: any) => Object.keys(obj)))
  ).map((key) => ({ name: key as string }));

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetTableRows());
  }, [dispatch]);

  const Modal: React.FC = () => {
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);
    const {
      statusCode,
      message,
      isPending: putPending,
    } = usePutData(
      ENDPOINTS.PUT_ANAGRAPHIC_REPORT(clientId),
      rowsSelector,
      modalConfirm
    );
    const handleModalConfirm = () => {
      setModalConfirm(true);
    };

    const handleMessageOnClose = () => {
      if (statusCode === 201) {
        dispatch(resetTableRows());
        navigate('/');
      }
    };

    return (
      <div>
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            Sei sicuro di voler continuare?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="flex flex-col space-y-6"
            >
              <Typography component={'span'} variant={'body1'}>
                Di seguito un riepilogo delle modifiche effettuate
              </Typography>
              <BaseTable columns={modalColumns} rows={rowsSelector} />
              <Typography component={'span'} variant={'body1'}>
                Clicca conferma per confermare le modifiche
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Annulla</Button>
            <LoadingButton
              onClick={handleModalConfirm}
              loading={putPending}
              autoFocus
            >
              Conferma
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={!putPending && message !== undefined}
          onClose={handleMessageOnClose}
          autoHideDuration={2000}
          message={message}
        />
      </div>
    );
  };

  return (
    <BaseLayout isLoading={isPending} errorMessage={errorMessage}>
      <div className="flex flex-col w-full justify-center">
        <div className="my-10 mx-auto">
          <Typography fontSize="2.5rem" fontWeight={700}>
            {clientName}
          </Typography>
        </div>
        <div className="mx-10 mb-10 px-10 py-4 bg-sky-100 rounded-lg">
          <div className="mb-4">
            <Typography fontSize="1.2rem" fontWeight={600}>
              Modifica dati anagrafici
            </Typography>
          </div>
          <div className="flex flex-col space-y-2">
            <EditableAnagraphicTable
              clientId={clientId}
              classNames="max-h-[75vh] !rounded-lg"
            />
            <div className="flex flex-row w-full justify-end space-x-4">
              <Button
                component={Link}
                to="/"
                className="mr-2"
                variant="outlined"
              >
                Annulla
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                onClick={handleModalOpen}
                disabled={rowsSelector.length === 0}
              >
                Applica modifiche
              </Button>
            </div>
          </div>
        </div>
        <Modal />
      </div>
    </BaseLayout>
  );
};
export default EditClientAnagraphy;
