import axios, { Method } from 'axios';
import { useEffect, useState } from 'react';
import { ENDPOINTS } from './EndPoints';
import { apiUrl } from '../App';
import { useDispatch } from 'react-redux';
import { setTableHeaders } from '../actions/anagraphicTableActions';
import { Dict } from '../schema/general';

export const useFetchData = (endpoint: string, fetch: boolean = true) => {
  const [apiData, setApiData] = useState<any>(null);
  const [isPending, setIsPending] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const token: string | null = localStorage.getItem('token');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${apiUrl}/api${endpoint}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
        .then((res) => {
          setApiData(res.data);
          setIsPending(false);
          if (endpoint.startsWith('/reports/anagraphic')) {
            dispatch(setTableHeaders(res.data.report_columns));
          }
        })
        .catch((error: any) => {
          if (error.response) {
            setErrorMessage({
              code: error.response.status,
              content: error.response.data,
            });
          } else if (error.request) {
            setErrorMessage(error.request);
          } else {
            setErrorMessage(error.message);
          }
          if (error.response && [401, 403].includes(error.response.status)) {
            const redirectUrl = error.response.data.detail.login_url;
            window.location.href = redirectUrl;
          }
        });
    };

    const postLogout = async () => {
      localStorage.removeItem('token');
      await axios
        .get(`${apiUrl}/api${ENDPOINTS.LOGOUT}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
        .then((res) => {
          setIsPending(false);
          window.location.href = res.data.logout_url;
        });
    };
    if (fetch) {
      setIsPending(true);
      if (endpoint === ENDPOINTS.LOGOUT) postLogout();
      else fetchData();
    }
  }, [dispatch, endpoint, fetch, token]);

  return { apiData, isPending, errorMessage };
};

export const usePutData = (
  endpoint: string,
  body: Dict | Dict[],
  put: boolean = true
) => {
  const [statusCode, setStatusCode] = useState<number>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [message, setMessage] = useState<string>();
  const token: string | null = localStorage.getItem('token');

  useEffect(() => {
    const putData = async () => {
      await axios
        .put(`${apiUrl}/api${endpoint}`, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
        .then((res) => {
          setStatusCode(res.status);
          setIsPending(false);
          setMessage(res.data.message);
        })
        .catch((error: any) => {
          if (error.response) {
            setErrorMessage({
              code: error.response.status,
              content: error.response.data,
            });
          } else if (error.request) {
            setErrorMessage(error.request);
          } else {
            setErrorMessage(error.message);
          }
          if (error.response && [401, 403].includes(error.response.status)) {
            const redirectUrl = error.response.data.detail.login_url;
            window.location.href = redirectUrl;
          }
        });
    };
    if (put) {
      setIsPending(true);
      putData();
    }
  }, [body, endpoint, put, statusCode, token]);

  return { statusCode, isPending, message, errorMessage };
};

// TODO: useApiCall dovrebbe estedersi per essere utilizzata al posto delle funzioni custom sopra, tanto lavoro ma neccessario
export function useApiCall() {
  const [isPending, setIsPending] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const token: string | null = localStorage.getItem('token');

  const makeCall = async (
    endpoint: string,
    method: Method,
    data?: Dict | any
  ): Promise<any> => {
    setIsPending(true);
    setErrorMessage(null);
    try {
      const response = await axios({
        baseURL: `${apiUrl}/api`,
        url: endpoint,
        method: method,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          method: 'POST',
        },
      });

      console.log(response.data);
      return response;
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
      return Promise.reject(error);
    } finally {
      setIsPending(false);
    }
  };

  return { makeCall, isPending, errorMessage };
}
