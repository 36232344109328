import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { MenuItemProps } from './Select/BasicSelect';

interface AutoCompleteSelectProps {
  placeholder: string;
  defaultSelected?: string;
  menuItems: MenuItemProps[];
  isLoading?: boolean;
  toggleSelectState: (value: string) => void;
}

const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
  placeholder,
  defaultSelected = '',
  menuItems,
  isLoading,
  toggleSelectState,
}) => {
  const [options, setOptions] = useState<MenuItemProps[]>([]);
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => toggleSelectState(value?.value || '');
  useEffect(() => {
    if (!isLoading) {
      setOptions([...menuItems]);
    }
  }, [isLoading, menuItems]);

  return (
    <Box sx={{ minWidth: 300, width: '100%' }}>
      <Autocomplete
        loading={isLoading}
        disablePortal
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleChange}
        id="AutoCompleteSelect"
        options={options}
        sx={{ width: 300 }}
        groupBy={(option) => option.group || ''}
        getOptionDisabled={(option) => option.disabled ?? false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutoCompleteSelect;
