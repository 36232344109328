import { useGetClientsSummary } from '../api/ClientsApi';
import { useGetPercentPassedReports } from '../api/ReportsAPI';

export const useGetSummary = () => {
  const {
    percentPassedReports,
    isPending: percentPassedPending,
    errorMessage: percentErrorMessage,
  } = useGetPercentPassedReports();
  const {
    clientsSummary,
    isPending: clientsSummaryPending,
    errorMessage: summaryErrorMessage,
  } = useGetClientsSummary();
  const isPending = percentPassedPending || clientsSummaryPending;
  const errorMessage = percentErrorMessage ?? summaryErrorMessage;
  return { percentPassedReports, clientsSummary, isPending, errorMessage };
};
