import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularProgressWithLabelProps {
  value?: number;
  size?: number;
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value = 0,
  size,
}) => {
  let color: CircularProgressProps['color'];
  if (value >= 70) {
    if (value === 100) {
      color = 'success';
    } else {
      color = 'warning';
    }
  } else {
    color = 'error';
  }
  const circleSize = size ? size * 10 : 60;
  const fontSize = Math.round(circleSize / 4);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        value={value}
        size={circleSize}
        variant="determinate"
        color={color}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          component="div"
          color={`${color}.main`}
          fontSize={fontSize}
        >
          {`${Math.floor(value * 10) / 10}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
