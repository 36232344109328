/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import SearchInput from '../SearchInput';
import CircularProgressWithLabel from '../CircularStatus';
import { CLientSummaryProps } from '../../schema/clients';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface SortedTableColumnProps {
  id: keyof CLientSummaryProps;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CLientSummaryProps
  ) => void;
  order: Order;
  orderBy: string;
  columns: readonly SortedTableColumnProps[];
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { columns, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof CLientSummaryProps) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow className="bg-primary">
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              <Typography color="white" fontSize="1rem" fontWeight={600}>
                {column.label}
              </Typography>

              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface SortedTableCellProps {
  columnId: any;
  link: any;
  value: any;
}

const SortedTableCell: React.FC<SortedTableCellProps> = ({
  columnId,
  link,
  value,
}) => {
  let cellContent;
  switch (columnId) {
    case 'total_reports_percent':
      cellContent = (
        <Link href={link} underline="always">
          <CircularProgressWithLabel value={value} size={5} />
        </Link>
      );
      break;

    case 'is_anagraphic_reports':
      cellContent = (
        <Button component={RouterLink} to={link} disabled={!value}>
          Modifica
        </Button>
      );

      break;

    default:
      cellContent = (
        <Link href={link} underline="always">
          {value}
        </Link>
      );
      break;
  }
  return <TableCell align="center">{cellContent}</TableCell>;
};

interface SortedTableProps {
  rows: CLientSummaryProps[];
  columns: readonly SortedTableColumnProps[];
  classNames?: string;
}

const SortedTable: React.FC<SortedTableProps> = ({
  rows,
  columns,
  classNames = '',
}) => {
  const [tableRows, updateTableRows] = useState(rows);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof CLientSummaryProps>('client_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CLientSummaryProps
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      tableRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .sort(getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, tableRows]
  );
  const requestSearch = (searchedVal: string) => {
    const filteredRows =
      searchedVal !== ''
        ? rows.filter((row) =>
            row.client_name.toLowerCase().includes(searchedVal.toLowerCase())
          )
        : rows;

    updateTableRows(filteredRows);
  };

  if (rows.length === 0) {
    return (
      <Box sx={{ width: '100%', marginTop: '5rem' }}>
        <Typography
          className="text-center"
          variant="h1"
          component="div"
          color="#333"
          fontSize="3rem"
          fontWeight="700"
        >
          Non sono presenti segnalazioni ;)
        </Typography>
      </Box>
    );
  }

  const cellsLinksMapping: any = {
    critical_reports_count: (client_id: string | number) =>
      `/reports?client_cod=${client_id}&order=severity.desc`,
    total_reports_percent: (client_id: string | number) =>
      `/reports?client_cod=${client_id}`,
    functional_reports_count: (client_id: string | number) =>
      `/reports?client_cod=${client_id}`,
    is_anagraphic_reports: (client_id: string | number) =>
      `/edit_client_anagraphy?client_cod=${client_id}`,
  };

  const handleRequestSearch = (event: any) => {
    requestSearch(event);
  };

  return (
    <div className={`w-full ${classNames}`}>
      <Paper
        sx={{ width: '100%', mb: 2 }}
        className="!shadow-table !rounded-lg"
      >
        <div className="flex justify-end p-1">
          <SearchInput
            placeholder="Cerca un cliente"
            onChange={handleRequestSearch}
          />
        </div>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index: number) => (
                <TableRow hover tabIndex={-1} key={`${row.client_id}_${index}`}>
                  {columns.map((column, index: number) => {
                    const link =
                      column.id in cellsLinksMapping
                        ? cellsLinksMapping[column.id](row.client_id)
                        : '';
                    const cellContent = row[column.id];
                    if (row[column.id] !== 0 && link !== '') {
                      return (
                        <SortedTableCell
                          columnId={column.id}
                          value={cellContent}
                          link={link}
                        />
                      );
                    } else {
                      return (
                        <TableCell align="center" key={`${column.id}_${index}`}>
                          {cellContent}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default SortedTable;
