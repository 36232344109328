import { TableRow, TableCell, Skeleton } from '@mui/material';

interface TableRowsLoaderProps {
  numRows: number;
  numCols: number;
}

const TableRowsLoader: React.FC<TableRowsLoaderProps> = ({
  numRows,
  numCols,
}) => {
  return (
    <>
      {[...Array(numRows)].map((row, index) => (
        <TableRow key={`TableRowsLoader_${index}`}>
          {[...Array(numCols)].map((col, index) => (
            <TableCell
              key={`TableColsLoader_${index}`}
              component="th"
              scope="row"
            >
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableRowsLoader;
