import {
  ReportDetailProps,
  percentPassedReportsProps,
} from '../schema/reports';
import { useFetchData } from './ApiManager';
import { ENDPOINTS } from './EndPoints';
import { useSelector } from 'react-redux';

export const useGetPercentPassedReports: () => percentPassedReportsProps =
  () => {
    const { apiData, isPending, errorMessage } = useFetchData(
      ENDPOINTS.GET_PERCENT_PASSED
    );
    const percentPassedReports: number = apiData?.percent_passed_reports;

    return { percentPassedReports, isPending, errorMessage };
  };

export const useGetReportDetail: (id: string) => ReportDetailProps = (
  id: string
) => {
  const fetch = id !== '';
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_REPORT(id),
    fetch
  );
  const reportId = apiData?.report_id;
  const reportName = apiData?.report_name;
  const reportDescription = apiData?.report_description;
  const reportDate = apiData?.report_date;
  const reportDetail = apiData?.report_detail.data;
  const reportIsWhiteListable = apiData?.report_is_whitelistable;
  const reportDetailColumns = apiData?.report_detail.columns.map(
    (column: any) => ({
      name: column.name,
      isFocus: column.is_focus,
    })
  );
  return {
    reportId,
    reportName,
    reportDescription,
    reportDate,
    reportDetail,
    reportIsWhiteListable,
    reportDetailColumns,
    isPending,
    errorMessage,
  };
};

export const useGetAnaReports = (clientId: string, metagroup?: string) => {
  const endpoint = ENDPOINTS.GET_ANA_REPORTS_BY_CLIENT(clientId, metagroup);

  const { apiData, isPending, errorMessage } = useFetchData(endpoint);
  const reportsTypeList = apiData?.reports_list.map((report: any) => ({
    label: report.report_name,
    value: report.report_id,
    group: report.report_group,
    disabled: report?.last_report_passed,
  }));
  return { reportsTypeList, isPending, errorMessage };
};

export const useGetReportsWithFilters = (
  clientId: string,
  reportId: string,
  ordering?: { field: string; order: string },
  metagroup?: string
) => {
  const fetch = clientId !== '' || reportId !== '';
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_REPORTS_WITH_FILTERS(clientId, reportId, ordering, metagroup),
    fetch
  );
  const reportsList = apiData?.reports_result.map((report: any) => ({
    reportName: report.report_name,
    reportId: report.report_id,
    reportClientId: apiData.client_id,
    isCritical: report?.report_is_critical,
  }));
  return { reportsList, isPending, errorMessage };
};

export const useGetAnagraphicReportsWithFilters = (
  clientId: string,
  page?: number,
  perPage?: number,
  order?: string,
  orderBy?: string,
  searchValue?: string,
  searchBy?: string
) => {
  const fetch = clientId !== '';
  const { apiData, isPending, errorMessage } = useFetchData(
    ENDPOINTS.GET_ANAGRAPHIC_REPORTS_WITH_FILTERS(
      clientId,
      page,
      perPage,
      order,
      orderBy,
      searchValue,
      searchBy
    ),
    fetch
  );
  const selectorColumns = useSelector((state: any) => state.headers);
  const reportId = apiData?.report_id;
  const columns = apiData?.report_columns || selectorColumns;
  const reportColumns = columns?.map((column: any) => ({
    name: column.name,
    isFocus: column.is_focus,
    possibleValues: column.possible_values,
  }));
  const reportRows = apiData?.report_rows.map((row: any) => ({
    rowData: row.row_data,
    rowProperty: {
      editableCells: row.row_property.editable_cells,
      inEditCells: [],
    },
  }));
  const pagination = {
    totalRecords: apiData?.pagination.total_records,
  };
  return {
    reportId,
    reportColumns,
    reportRows,
    pagination,
    isPending,
    errorMessage,
  };
};
