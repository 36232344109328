export const SET_TABLE_HEADERS = 'SET_TABLE_HEADERS';
export const UPDATE_TABLE_ROWS = 'UPDATE_TABLE_ROWS';
export const RESET_TABLE_ROWS = 'RESET_TABLE_ROWS';

export const setTableHeaders = (headers: any) => ({
  type: SET_TABLE_HEADERS,
  payload: headers,
});

export const updateTableRows = (rows: any) => ({
  type: UPDATE_TABLE_ROWS,
  payload: rows,
});

export const resetTableRows = () => ({
  type: RESET_TABLE_ROWS,
  payload: {},
});
