import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './pages/Home';
import Reports from './pages/Reports';
import EditClientAnagraphy from './pages/EditClientAnagraphy';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import headerReducer from './reducers/anagraphicTableReducer';

const baseTheme = createTheme({
  typography: {
    // fontFamily: 'Inter', Fix, not working in production
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});
const store = createStore(headerReducer);

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={baseTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reports" element={<Reports />} />
          <Route
            path="/edit_client_anagraphy"
            element={<EditClientAnagraphy />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  </Provider>
);
export const apiUrl = process.env.REACT_APP_API_URL;

export default App;
